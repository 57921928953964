import { createRouter, createWebHistory } from "vue-router";
import FullLayout from "../views/FullLayout.vue";
import Homepage from "../components/website/Homepage.vue";
import AboutUs from "../components/website/AboutUs.vue";
import Mentors from "../components/website/Mentors.vue";
// import AboutUs from "../components/website/Mentors.vue";
import Contact from "../components/website/Contact.vue";
import WebLayout from "../views/WebLayout.vue";
import Dashboard from "../components/Dashboard.vue";
import MyRequest from "../components/MyRequest.vue";
import MySchedule from "../components/MySchedule.vue";
import SessionHistory from "../components/SessionHistory.vue";
import FeedbackAndRatings from "../components/FeedbackAndRatings.vue";
import AccountAndSettings from "../components/AccountAndSettings.vue";
import AccountSettings from "../components/AccountSettings.vue";
import Notifications from "../components/Notifications.vue";
import Plan from "../components/Plan.vue";
import Billing from "../components/Billing.vue";

const routes = [
  {
    path: "/dashboard",
    component: FullLayout,
    children: [
      {
        path: "",
        component: Dashboard,
      },
      {
        path: "/my-request",
        component: MyRequest,
      },
      {
        path: "/my-schedule",
        component: MySchedule,
      },
      {
        path: "/session-history",
        component: SessionHistory,
      },
      {
        path: "/feedback-&-ratings",
        component: FeedbackAndRatings,
      },
      {
        path: "/account-&-settings",
        component: AccountAndSettings,
        children: [
          // {
          //   path: "",
          //   component: AccountSettings,
          // },
          {
            path: "/account-settings",
            component: AccountSettings,
          },
          {
            path: "/notifications",
            component: Notifications,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: WebLayout,
    children: [
      {
        path: "",
        component: Homepage,
      },
      {
        path: "/about",
        component: AboutUs,
      },
      {
        path: "/mentors",
        component: Mentors,
      },
      {
        path: "/contact",
        component: Contact,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

// {
//   path: "/about",
//   name: "about",
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () =>
//     import(/* webpackChunkName: "about" */ "../components/AboutView.vue"),
// lazyloadingsyntax},
