<template>
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <div class="wt-bnr-inr overlay-wraper bg-center">
      <div class="overlay-main site-bg-white opacity-01"></div>
      <div class="container d-flex align-items-center">
        <div
          class="wt-bnr-inr-entry m-t100 w-100 d-flex align-items-center justify-content-center"
        >
          <div class="banner-title-outer">
            <div
              class="banner-title-name"
              data-aos="fade-up"
              data-aos-once="false"
            >
              <h2 class="wt-title">Mentors to Elevate Your Journey</h2>
              <p class="wt-p">
                Connect with mentors who’ve been in your shoes and can provide
                the guidance you need to succeed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- 1 SECTION START -->
    <div
      class="section-full p-b90 site-bg-gray twm-job-categories-area2 mentorsarea"
    >
      <div class="container">
        <div class="row rg-25">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="card" data-aos="flip-down" data-aos-duration="1000">
              <div class="profile-pic-container">
                <div class="outer-circle">
                  <div class="inner-circle">
                    <img
                      src="../../assets/template/images/dummyman.png"
                      class="h-100 w-100 of-cover"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h4>John Doe</h4>
              <p class="designation">Senior Software Engineer at Google</p>
              <p class="skills">
                8+ years in full-stack development | Tech speaker | Mentor for
                software engineers United States of America
              </p>
              <span class="email">john@doe.com</span>
              <ul class="social-ul">
                <li class="social-icon-container">
                  <i class="pi pi-facebook"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-twitter"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-instagram"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-linkedin"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="card" data-aos="flip-down" data-aos-duration="1000">
              <div class="profile-pic-container">
                <div class="outer-circle">
                  <div class="inner-circle">
                    <img
                      src="../../assets/template/images/dummyman.png"
                      class="h-100 w-100 of-cover"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h4>John Doe</h4>
              <p class="designation">Senior Software Engineer at Google</p>
              <p class="skills">
                8+ years in full-stack development | Tech speaker | Mentor for
                software engineers United States of America
              </p>
              <span class="email">john@doe.com</span>
              <ul class="social-ul">
                <li class="social-icon-container">
                  <i class="pi pi-facebook"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-twitter"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-instagram"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-linkedin"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="card" data-aos="flip-down" data-aos-duration="1000">
              <div class="profile-pic-container">
                <div class="outer-circle">
                  <div class="inner-circle">
                    <img
                      src="../../assets/template/images/dummyman.png"
                      class="h-100 w-100 of-cover"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h4>John Doe</h4>
              <p class="designation">Senior Software Engineer at Google</p>
              <p class="skills">
                8+ years in full-stack development | Tech speaker | Mentor for
                software engineers United States of America
              </p>
              <span class="email">john@doe.com</span>
              <ul class="social-ul">
                <li class="social-icon-container">
                  <i class="pi pi-facebook"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-twitter"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-instagram"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-linkedin"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="card" data-aos="flip-down" data-aos-duration="1000">
              <div class="profile-pic-container">
                <div class="outer-circle">
                  <div class="inner-circle">
                    <img
                      src="../../assets/template/images/dummyman.png"
                      class="h-100 w-100 of-cover"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h4>John Doe</h4>
              <p class="designation">Senior Software Engineer at Google</p>
              <p class="skills">
                8+ years in full-stack development | Tech speaker | Mentor for
                software engineers United States of America
              </p>
              <span class="email">john@doe.com</span>
              <ul class="social-ul">
                <li class="social-icon-container">
                  <i class="pi pi-facebook"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-twitter"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-instagram"></i>
                </li>
                <li class="social-icon-container">
                  <i class="pi pi-linkedin"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 SECTION END -->
  </div>
</template>
  
  <script>
export default {};
</script>
  
<style scoped>
.wt-title {
  color: black !important;
  font-size: 56px !important;
  font-weight: 700 !important;
  line-height: 60px !important;
  letter-spacing: -0.02em !important;
  text-align: center !important;
}

.wt-p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: rgba(122, 122, 122, 1);
  margin-top: 32px;
}

.profile-pic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.of-cover {
  object-fit: cover;
}

.inner-circle {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: 3px solid var(--primary-blue);
  overflow: hidden;
}

.outer-circle {
  height: 90px;
  width: 90px;
  background: linear-gradient(
    to right,
    #ffffff 20%,
    rgba(144, 179, 205, 1) 40%
  );
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  padding: 20px;
  width: 100%;
  /* height: 395px; */
  border-bottom: 4px solid rgba(51, 87, 113, 1);
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  background: rgba(252, 252, 250, 1);
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.mentorsarea {
  background: white;
}

.card h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.designation {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
  color: var(--primary-blue);
  margin-bottom: 15px;
}

.skills {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}

.email {
  height: 38px;
  width: fit-content;
  background: var(--primary-blue);
  border-radius: 8px;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  margin: 15px 0;
}

.social-icon-container {
  width: 44px;
  height: 44px;
  padding: 12px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  transition: 0.3s linear all;
}
.social-icon-container i {
  font-size: 20px;
  color: var(--primary-blue);
  transition: 0.3s linear all;
}

.social-ul {
  margin-bottom: 0;
  margin-top: 16px;
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.social-ul li {
  cursor: pointer;
}

.social-icon-container:hover {
  background: var(--primary-blue);
}
.social-icon-container:hover i {
  color: white;
}

.rg-25 {
  row-gap: 25px;
}
</style>