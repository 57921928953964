<template>
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <div class="wt-bnr-inr overlay-wraper bg-center">
      <div class="overlay-main site-bg-white opacity-01"></div>
      <div class="container d-flex align-items-center">
        <div
          class="wt-bnr-inr-entry m-t100 d-flex align-items-center justify-content-center"
        >
          <div class="banner-title-outer">
            <div
              class="banner-title-name"
              data-aos="fade-up"
              data-aos-once="false"
            >
              <h2 class="wt-title">Empowering Mentorship And Careers</h2>
              <p class="wt-p">
                Mentorwise simplifies your path to success by connecting you to
                expert guidance and valuable opportunities, unlocking the career
                conversations and certifications that fuel your growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- 1 SECTION START -->
    <div
      class="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area2"
    >
      <div class="container">
        <div class="row flex-lg-row flex-column-reverse">
          <div
            class="col-lg-6 col-sm-12 sec1-col1 d-flex align-items-end justify-content-center"
          >
            <img
              src="../../assets/template/images/about1.png"
              alt=""
              data-aos="slide-up"
              data-aos-duration="1000"
              data-aos-once="true"
            />
          </div>
          <div class="col-lg-6 col-sm-12 sec1-col2">
            <span class="subheading">Your Career Starts Here</span>
            <h3
              class="heading"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Simplifying Mentorship, Amplifying Impact
            </h3>
            <button
              class="contact-now-btn"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <i class="pi pi-arrow-right r1"></i><span>Contact Now</span
              ><i class="pi pi-arrow-right r2"></i>
            </button>
            <hr />
            <p
              class="paragraph"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              With our interface, you can easily schedule calls, follow up with
              your mentor, and track progress—no more time-zone mismatches or
              scheduling headaches. It's mentorship for the modern
              world—organized, flexible, and always ready when you are.
            </p>
            <p
              class="subparagraph"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Connecting You to Guidance, Easily
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 SECTION END -->

    <!-- 2 SECTION START -->
    <div
      class="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area2"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 sec2-col1">
            <span class="subheading">Navigating You to dream Job</span>
            <h3
              class="heading"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Transforming Chats into Career Opportunities
            </h3>
            <button
              class="contact-now-btn"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <i class="pi pi-arrow-right r1"></i><span>Contact Now</span
              ><i class="pi pi-arrow-right r2"></i>
            </button>
            <hr />
            <p
              class="paragraph"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Finding the right job should be about more than just applying, our
              platform bridges the gap between applicants and employers through
              direct chat. No waiting for formal emails—now you can connect, ask
              questions, , bringing you closer to the opportunities for success.
            </p>
            <p
              class="subparagraph"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Engage, Connect, Succeed
            </p>
          </div>
          <div
            class="col-lg-6 col-sm-12 sec2-col2 d-flex align-items-end justify-content-center"
          >
            <img
              src="../../assets/template/images/about2.png"
              alt=""
              data-aos="slide-up"
              data-aos-duration="1000"
              data-aos-once="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 2 SECTION END -->

    <!-- last section -->
    <div class="section-full last-section p-t90 p-b90 p-l50 p-r50 site-bg-gray">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <img
              src="../../assets/template/images/about3.png"
              alt=""
              class="h-100 w-100"
              data-aos="zoom-in-right"
              data-aos-duration="1000"
              data-aos-once="true"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <img
              src="../../assets/template/images/about4.png"
              alt=""
              class="h-100 w-100"
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              data-aos-once="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- last section end -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wt-bnr-inr {
  background-image: url(../../assets/template/images/about-banner.png);
}

.wt-p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  color: rgba(252, 252, 250, 1);
}

.subheading {
  color: rgba(51, 87, 113, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.heading {
  font-size: 52px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: -2px;
  text-align: left;
  margin: 18px 0;
}

hr {
  margin-top: 80px !important;
  margin-bottom: 20px !important;
}

.paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.subparagraph {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.section-full {
  background: rgba(250, 250, 250, 1) !important;
}
.sec1-col1 {
  background: rgba(207, 223, 233, 1);
}

.sec2-col2 {
  background: rgba(241, 241, 241, 1);
}

.sec1-col1 img {
  height: 85%;
  object-fit: scale-down;
  width: auto;
}
.sec2-col2 img {
  height: 90%;
  object-fit: scale-down;
  width: auto;
}
.sec1-col2 {
  padding-left: 60px !important;
}

.sec2-col1 {
  padding-left: initial !important;
  padding-right: 60px !important;
}

.last-section {
  background: white !important;
}
.last-section img {
  object-fit: cover;
}

@media (max-width: 992px) {
  .sec1-col2,
  .sec2-col1 {
    padding-left: initial !important;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heading,
  .subheading,
  .paragraph,
  .subparagraph {
    text-align: center;
  }
}
</style>
