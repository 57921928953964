<template>
  <nav class="navbar navbar-static-top">
    <div class="d-flex align-items-center">
      <!-- <a class="navbar-brand" href="#"> -->
      <div class="logo-container">
        <img
          src="../assets/images/logos/MW-white.png"
          class="logo h-100 w-100"
          alt="Logo"
        />
      </div>
      <!-- </a> -->
      <IconField iconPosition="left">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText class="search_field" placeholder="Search Mentor" />
      </IconField>
    </div>
    <div class="h-100 options_container">
      <div class="d-flex align-items-center left-cont">
        <Button class="post_request_btn" label="Post Request" />
        <div class="icons-container">
          <img
            src="../assets/images/icons/message_icon.png"
            alt="Icon"
            class="icon"
          />
          <img src="../assets/images/icons/bell_icon.png" class="icon" alt="" />
        </div>
      </div>
      <div class="right-cont">
        <Avatar label="J" class="mr-2" shape="circle" />
        <div class="user_info">
          <span>John Doe</span>
          <span>Mentor</span>
        </div>
        <InputIcon class="pi pi-bars"> </InputIcon>
      </div>
    </div>
  </nav>
</template>

<script>
import Button from "primevue/button";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";

export default {
  components: {
    Button,
    IconField,
    InputIcon,
    InputText,
    Avatar,
  },
};
</script>

<style scoped>
nav {
  height: 70px;
  background-color: var(--primary-blue);
  padding: 0 50px;
}

.logo-container {
  width: 149px;
  height: 30px;
  margin-right: 100px;
}

.search_field {
  border-radius: 19px;
  width: 443px;
  height: 38px;
  font-weight: 400;
  font-size: 12px;
}

.pi-search {
  color: black;
}

.post_request_btn {
  background: var(--green-color);
  font-weight: 500;
  font-size: 15px;
  border-radius: 19px;
  border: 1px solid var(--green-color);
  margin-right: 50px;
}

.left-cont {
  height: 100%;
  display: flex;
  border-right: 0.8px solid #ffffff9c;
  padding-right: 20px;
}

.right-cont {
  height: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.options_container {
  display: flex;
  align-content: center;
}

.icons-container {
  display: flex;
  gap: 15px;
}

.icons-container .icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.user_info {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-left: 15px;
}

.user_info span:first-child {
  font-size: 18px;
  font-weight: 500;
}
.user_info span:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
}

.pi-bars {
  color: #fff;
  font-size: 25px;
  margin-left: 60px;
}

.right-cont ::v-deep .p-avatar {
  height: 40px;
  width: 40px;
}

@media (max-width: 1316px) {
  .search_field {
    width: 265px;
  }

  .nav {
    padding: 0 30px;
  }
}

@media (max-width: 1140px) {
  .left-cont {
    display: none !important;
  }
}

@media (max-width: 870px) {
  .search_field {
    width: 165px;
  }
}
</style>

