<template>
  <div class="row">
    <div class="content flex-grow-1">
      <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" href="#">
        Open
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">
        Closed
        </a>
      </li>
      </ul>
      <div class="mt-4">
      <h5>
        Need help from an expert?
      </h5>
      <p>
        Click on the "POST REQUEST" button to find a mentor or developer.
        <span aria-label="waving hand" role="img">
        👋
        </span>
      </p>
      <hr/>
      <h5>
        How other people use MentorWise
      </h5>
      <div class="d-flex justify-content-between">
        <div class="card">
          <div class="d-flex align-items-center">
            <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/aziVbmMG775cJxaGsnNEcheALsJqSnqhkG2l6hxvaHQ3XLyJA.jpg" width="50"/>
            <div class="ms-3">
            <h6 class="card-title">
              Patrick Doe
            </h6>
            <p class="card-text">
              Senior iOS Developer
            </p>
            </div>
          </div>
          <p class="card-text mt-3">
            "MentorWise helps me find another pair of eyes to look at my code. They help me easily learning new software technology."
          </p>
        </div>
        <div class="card">
          <div class="d-flex align-items-center">
            <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/aziVbmMG775cJxaGsnNEcheALsJqSnqhkG2l6hxvaHQ3XLyJA.jpg" width="50"/>
            <div class="ms-3">
            <h6 class="card-title">
              Patrick Doe
            </h6>
            <p class="card-text">
              Senior iOS Developer
            </p>
            </div>
          </div>
          <p class="card-text mt-3">
            "MentorWise helps me find another pair of eyes to look at my code. They help me easily learning new software technology."
          </p>
        </div>
        <div class="card">
          <div class="d-flex align-items-center">
            <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/aziVbmMG775cJxaGsnNEcheALsJqSnqhkG2l6hxvaHQ3XLyJA.jpg" width="50"/>
            <div class="ms-3">
            <h6 class="card-title">
              Patrick Doe
            </h6>
            <p class="card-text">
              Senior iOS Developer
            </p>
            </div>
          </div>
          <p class="card-text mt-3">
            "MentorWise helps me find another pair of eyes to look at my code. They help me easily learning new software technology."
          </p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.content {
  padding: 20px;
}
.content h5 {
  font-size: 1.25rem;
  margin-bottom: 20px;
}
.content .card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 2rem;
}
.content .card img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.content .card-title {
  font-size: 1rem;
  margin-bottom: 5px;
}
.content .card-text {
  font-size: 0.875rem;
  color: #555;
}
</style>
