<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0"><Navbar /></div>
      <div class="d-flex bottom_container">
        <div class="col-md-2">
          <SideBar />
        </div>
        <div class="col-md-10 middle_content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import Navbar from "../components/Navbar.vue";
import "../assets/css/dashboard-styles.css";

export default {
  name: "FullLayout",
  components: { SideBar, Navbar },
};
</script>

<style scoped>
.bottom_container {
  padding: 0 30px;
  background: var(--primary-bg);
}

.middle_content {
  padding: 40px 12px;
  padding-right: 0;
}
</style>