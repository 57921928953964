<template>
  <footer class="footer-dark">
    <div class="container">
      <!-- NEWS LETTER SECTION START -->
      <!-- <div class="ftr-nw-content">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="ftr-nw-title">
                                Join our email subscription now to know more about our extraordinary mentors and get notified for discounted or even free sessions! 
                            </div>
                        </div>
                        <div class="col-md-7">
                            <form>
                                <div class="ftr-nw-form">
                                    <input name="news-letter" class="form-control" placeholder="Enter Your Email" type="text">
                                    <button class="ftr-nw-subcribe-btn">Subscribe Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> -->
      <!-- NEWS LETTER SECTION END -->
      <!-- FOOTER BLOCKES START -->
      <div class="footer-top">
        <div class="row">
          <div
            class="col-lg-4 col-md-12"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <div class="widget widget_about">
              <div class="logo-footer clearfix">
                <a href="index.html"
                  ><img src="../../assets/images/logos/MW-white.png" alt=""
                /></a>
              </div>
              <p>
                Empower Your Future with mentorwise .Now Learn, Certify, and
                Land Your Dream Job with your dream mentors !
              </p>
              <ul class="ftr-list p-0">
                <!-- <li><p><span>Address :</span>65 Sunset CA 90026, USA </p></li> -->
                <li><p class="footer_email">info@mentorwise.org</p></li>
                <li><p class="footer_phone">+971 4 336 6430</p></li>
              </ul>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <div class="row">
              <div class="widget widget_services ftr-list-center">
                <h3 class="widget-title">Quick Links</h3>
                <ul>
                  <li><a href="index.html">Home</a></li>
                  <li><a href="about-1.html">Mentors</a></li>
                  <li><a href="dash-bookmark.html">About Us</a></li>
                  <li><a href="job-grid.html">Contact Us</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <ul class="social-icons">
              <li class="social_buttons">
                <a href="https://www.facebook.com/mentorwiseedu/"
                  ><i class="fab fa-facebook-f"></i>Facebook</a
                >
              </li>
              <li class="social_buttons">
                <a href="https://twitter.com/mentorwiseedu"
                  ><i class="fab fa-twitter"></i>Twitter</a
                >
              </li>
              <li class="social_buttons">
                <a href="https://www.instagram.com/mentorwiseedu/"
                  ><i class="fab fa-instagram"></i>Instagram</a
                >
              </li>
              <li class="social_buttons">
                <a href="https://www.linkedin.com/company/mentorwiseedu/"
                  ><i class="fab fa-linkedin"></i>Linkedin</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- FOOTER COPYRIGHT -->
      <div class="footer-bottom">
        <div class="footer-bottom-info">
          <div class="footer-copy-right">
            <span class="copyrights-text"
              >Copyright © 2022 by ANALYTICA FZE.</span
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-dark {
  background-image: url("../../assets/template/images/Footer.png");
}
a {
  text-decoration: none;
}

.social_buttons {
  background: rgba(255, 255, 255, 0.08);
  height: 44px;
  padding: 12px;
  border-radius: 5px;
  display: flex !important;
  gap: 20px;
  align-items: center;
  width: 125px;
  margin-bottom: 12px;
  font-family: "Inter", sans-serif !important;
  cursor: pointer;
  transition: 0.3s linear all;
}

.social_buttons:hover {
  background: rgba(14, 118, 168, 1);
}

.social_buttons i {
  margin-right: 20px;
}
.footer-dark .col-lg-4:last-child {
  justify-content: end;
}

@media (max-width: 992px) {
  .footer-dark .col-lg-4:last-child {
    justify-content: start;
  }
}

.footer_email {
  font-family: "Inter", sans-serif;
  font-size: 24px !important;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 15px !important;
  color: white;
}

.footer_phone {
  color: rgba(180, 187, 197, 1);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
}
</style>
