<template>
  <div class="page-content">
    <!--Banner Start-->
    <div
      class="twm-home1-banner-section site-bg-gray bg-cover "
      style="
        background-image: url(../../assets/template/images/main-slider/slider1/bg1.jpg);
      "
    >
      <div class="row">
        <!--Left Section-->
        <div class="col-xl-6 col-lg-6 col-md-12 pl-30">
          <div class="twm-bnr-left-section">
            <div class="twm-bnr-title-small">
              Be the <span class="site-text-primary">Significant</span> Change
            </div>
            <div class="twm-bnr-title-large">
              Find or become the next
              <a
                href=""
                class="typewrite"
                data-period="2000"
                data-type='[ "Advisor", "Consultant", "Mentor", "Mentee" ]'
              >
                <span class="wrap"></span>
              </a>
            </div>
            <div class="twm-bnr-discription">
              Type down your area of interest and get started!
            </div>

            <div class="twm-bnr-search-bar shadow">
              <form>
                <div class="row">
                  <!--Title-->
                  <div class="form-group col-xl-8 col-lg-8 col-md-8">
                    <div class="twm-single-iput h-100">
                      <input
                        name="username"
                        type="text"
                        required=""
                        class="form-control bg-none h-100"
                        placeholder="Try Marketing, JAVA Script, UI UX"
                      />
                    </div>
                  </div>

                  <!--Find job btn-->
                  <div class="form-group col-xl-4 col-lg-4 col-md-4">
                    <button type="button" class="site-button">Search</button>
                  </div>
                </div>
              </form>
            </div>

            <div class="twm-bnr-popular-search bg-clr-white shadow">
              <div class="widget tw-sidebar-tags-wrap">
                <div class="container popular-search">
                  <h4 class="section-head-small mb-3">Popular Searches</h4>

                  <div class="tagcloud">
                    <a href="job-list.html">JAVASCRIPT MENTORS</a>
                    <a href="job-list.html">PRODUCT MANAGERS </a>
                    <a href="job-list.html">PYTHON</a>
                    <a href="job-list.html">MARKETING MANAGERS </a>
                    <a href="job-list.html">UX DESIGN MENTORS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--right Section-->
        <div class="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
          <div class="twm-bnr-right-content">
            <div class="twm-img-bg-circle-area">
              <div class="twm-img-bg-circle1 rotate-center"><span></span></div>
              <div class="twm-img-bg-circle2 rotate-center-reverse">
                <span></span>
              </div>
              <div class="twm-img-bg-circle3"><span></span></div>
            </div>

            <div class="twm-bnr-right-carousel">
              <div class="owl-carousel twm-h1-bnr-carousal">
                <div class="item">
                  <div class="slide-img">
                    <img
                      src="../../assets/template/images/main-slider/slider1/r-img1.png"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="item">
                  <div class="slide-img">
                    <div class="slide-img">
                      <img
                        src="../../assets/template/images/main-slider/slider1/r-img2.png"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Samll Ring Left-->
            <div class="twm-small-ring-l slide-top-animation"></div>
            <div class="twm-small-ring-2 slide-top-animation"></div>
          </div>
        </div>
      </div>
    </div>
    <!--Banner End-->

    <!-- HOW IT WORK SECTION START -->
    <div class="section-full p-t60 p-b60 site-bg-white twm-how-it-work-area findmentorsection">
      <div class="container">
        <div class="row">
          <div
            class="twm-how-it-work-section col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between"
          >
            <div>
              <h5>
                Find or become
                <span style="color: var(--primary-blue)">Mentor</span>
              </h5>
              <p class="section2subheading">
                Take control of your career journey with expert mentorship
                tailored just for you. Learn, innovate, and unlock your dream
                career alongside verified professionals. Here's what awaits you
              </p>
            </div>
            <div class="row countrow" ref="countUpSection">
              <div class="col-lg-4 col-md-4 col-sm-12 stat-col">
                <h6 class="stat">
                  <CountUp
                    v-if="isInView"
                    :startVal="0"
                    :endVal="5400"
                    :duration="2"
                    :separator="','"
                  />+
                </h6>
                <p class="stat2">Available Mentors</p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 stat-col">
                <h6 class="stat">
                  <CountUp
                    v-if="isInView"
                    :startVal="0"
                    :endVal="22900"
                    :duration="2"
                    :separator="','"
                  />+
                </h6>
                <p class="stat2">Job Matches</p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 stat-col">
                <h6 class="stat">
                  <CountUp
                    v-if="isInView"
                    :startVal="0"
                    :endVal="130"
                    :duration="2"
                    :separator="','"
                  />+
                </h6>
                <p class="stat2">Certications</p>
              </div>
            </div>
          </div>

          <div class="twm-how-it-work-section col-lg-6 col-md-12 col-sm-12">
            <div class="d-flex m-b30">
              <div class="d-flex align-items-start">
                <div class="icon-holder">
                  <img
                    src="../../assets/images/icons/become-mentor.svg"
                    alt="icon1"
                  />
                </div>
              </div>
              <div class="pl">
                <h4 class="twm-title">Become a Mentor</h4>
                <p class="twm-desc">
                  Share your knowledge and experience by registering as a mentor
                  and uploading your documents to be verified. You can earn as
                  you go, volunteer for free and update your CV.
                </p>
              </div>
            </div>
            <div class="d-flex m-b30">
              <div class="d-flex align-items-start">
                <div class="icon-holder">
                  <img
                    src="../../assets/images/icons/become-mentee.svg"
                    alt="icon1"
                  />
                </div>
              </div>
              <div class="pl">
                <h4 class="twm-title">Register as Mentee</h4>
                <p class="twm-desc">
                  Gain expert guidance, learn new skills, and accelerate your
                  career with personalized mentorship and support.
                </p>
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex align-items-start">
                <div class="icon-holder">
                  <img
                    src="../../assets/images/icons/updatecv.svg"
                    alt="icon1"
                  />
                </div>
              </div>
              <div class="pl">
                <h4 class="twm-title">Update your CV</h4>
                <p class="twm-desc">
                  Showcase Your Best Self with the latest skills, achievements,
                  and experiences to stand out in today’s job market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- HOW IT WORK SECTION END -->

    <!-- JOBS CATEGORIES SECTION START -->
    <div class="section-full site-bg-gray job-category-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-12 col-sm-12">
            <div class="section-head left wt-small-separator-outer">
              <div class="wt-small-separator site-text-primary">
                <div>Hunt your Job opportunity</div>
              </div>
              <h2 class="wt-title browsejobstitle">Browse Jobs by Category</h2>
            </div>
            <ul>
              <li
                v-for="(jc, index) in jobCategories"
                :key="index"
                class="jobcat-li"
              >
                <span>
                  <img src="../../assets/images/icons/checkmark.svg" alt="" />
                </span>
                {{ jc }}
              </li>
            </ul>
          </div>

          <div
            class="col-lg-7 col-md-12 col-sm-12 d-flex justify-content-end jcc2"
          >
            <img
              src="../../assets/template/images/jcmain.png"
              class="job-cat-img1"
              alt=""
            />

            <img
              src="../../assets/template/images/jc-t1.png"
              class="job-cat-t1"
              alt=""
              data-aos="zoom-in" data-aos-duration="1000"
            />
            <img
              src="../../assets/template/images/jc-t2.png"
              class="job-cat-t2"
              alt=""
              data-aos="zoom-in" data-aos-duration="1000"
            />
            <img
              src="../../assets/template/images/jc-t3.png"
              class="job-cat-t3"
              alt=""
              data-aos="zoom-in" data-aos-duration="1000"
            />
            <img
              src="../../assets/template/images/jc-t4.png"
              class="job-cat-t4"
              alt=""
              data-aos="zoom-in" data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- JOBS CATEGORIES SECTION END -->

    <!-- JOB POST START -->
    <div
      class=" p-t120 p-b90 site-bg-light-custom twm-bg-ring-wrap"
    >
      <div class="twm-bg-ring-right"></div>
      <div class="twm-bg-ring-left"></div>
      <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
          <div class="wt-small-separator site-text-primary">
            <div>All Jobs Post</div>
          </div>
          <h2 class="wt-title">Find Your Career You Deserve it</h2>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
          <div class="twm-jobs-list-wrap">
            <ul>
              <li data-aos="slide-right" data-aos-offset="50" data-aos-duration="1000"> 
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic1.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4>
                        Senior Web Designer , Developer
                        <span class="twm-job-post-duration">/ 1 days ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-green">New</span>
                    </div>
                    <div class="twm-jobs-amount">
                      $2500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li data-aos="slide-right" data-aos-offset="50" data-aos-duration="1000">
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic2.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4>
                        Need Senior Rolling Stock Technician<span
                          class="twm-job-post-duration"
                          >/ 15 days ago</span
                        >
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-brown">Intership</span>
                    </div>
                    <div class="twm-jobs-amount">$2000<span>/ Month</span></div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li data-aos="slide-right" data-aos-offset="50" data-aos-duration="1000">
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic3.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4 class="twm-job-title">
                        IT Department Manager & Blogger-Entrepenour
                        <span class="twm-job-post-duration">/ 6 Month ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-purple">Fulltime</span>
                    </div>
                    <div class="twm-jobs-amount">
                      $1500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li data-aos="slide-right" data-aos-offset="50" data-aos-duration="1000">
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic4.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4 class="twm-job-title">
                        Art Production Specialist
                        <span class="twm-job-post-duration">/ 2 days ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-sky">Freelancer</span>
                    </div>
                    <div class="twm-jobs-amount">$1200<span>/ Month</span></div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>

              <li data-aos="slide-right" data-aos-offset="50" data-aos-duration="1000">
                <div class="twm-jobs-list-style1 mb-5">
                  <div class="twm-media">
                    <img
                      src="../../assets/template/images/jobs-company/pic5.jpg"
                      alt="#"
                    />
                  </div>
                  <div class="twm-mid-content">
                    <a href="job-detail.html" class="twm-job-title">
                      <h4 class="twm-job-title">
                        Recreation & Fitness Worker
                        <span class="twm-job-post-duration">/ 1 days ago</span>
                      </h4>
                    </a>
                    <p class="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      class="twm-job-websites site-text-primary"
                      >https://thewebmax.com</a
                    >
                  </div>
                  <div class="twm-right-content">
                    <div class="twm-jobs-category green">
                      <span class="twm-bg-golden">Temporary</span>
                    </div>
                    <div class="twm-jobs-amount">
                      $1700 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      class="twm-jobs-browse site-text-primary"
                      >Browse Job</a
                    >
                  </div>
                </div>
              </li>
            </ul>
            <div class="text-center m-b30">
              <a href="job-list.html" class="site-button">Browse All Jobs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- JOB POST END -->

    <!-- TESTIMONIAL SECTION START -->
    <div class="section-full p-t120 p-b90 site-bg-white twm-testimonial-1-area">
      <div class="container">
        <div class="wt-separator-two-part">
          <div class="row wt-separator-two-part-row">
            <div class="col-xl-5 col-lg-6 col-md-12 wt-separator-two-part-left">
              <!-- TITLE START-->
              <div class="section-head left wt-small-separator-outer">
                <div class="wt-small-separator site-text-primary">
                  <div>Testimonials</div>
                </div>
                <p class="wt-title">
                  Our customers consistently rate mentor wise at the top for
                  overall satisfaction, user-friendly features, and
                  cost-effectiveness.
                </p>
              </div>
              <!-- TITLE END-->
            </div>
          </div>
        </div>

        <div class="section-content">
          <div
            class="owl-carousel twm-testimonial-1-carousel owl-btn-bottom-center"
          >
            <!-- COLUMNS 1 -->
            <div class="item" data-aos="zoom-in-up" data-aos-duration="1000">
              <div class="test-item">
                <div class="item-header d-flex align-items-center">
                  <span class="dp"
                    ><img
                      src="../../assets/template/images/dummyman.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 class="name">Annette Black</h6>
                    <p class="designation">CEO of Notion</p>
                  </div>
                </div>
                <div class="item-content">
                  <p>
                    Working with my mentor from this platform has been
                    transformative! Their guidance helped me clarify my career
                    goals and develop the skills I needed to succeed. Highly
                    recommend!"
                  </p>
                </div>
                <div class="item-footer">
                  <img
                    src="../../assets/template/images/amazon-logo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- COLUMNS 2 -->
            <div class="item" data-aos="zoom-in-up" data-aos-duration="1000">
              <div class="test-item">
                <div class="item-header d-flex align-items-center">
                  <span class="dp"
                    ><img
                      src="../../assets/template/images/dummyman.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 class="name">Annette Black</h6>
                    <p class="designation">CEO of Youtube</p>
                  </div>
                </div>
                <div class="item-content">
                  <p>
                    Working with my mentor from this platform has been
                    transformative! Their guidance helped me clarify my career
                    goals and develop the skills I needed to succeed. Highly
                    recommend!"
                  </p>
                </div>
                <div class="item-footer">
                  <img
                    src="../../assets/template/images/amazon-logo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- COLUMNS 3 -->
            <div class="item" data-aos="zoom-in-up" data-aos-duration="1000">
              <div class="test-item">
                <div class="item-header d-flex align-items-center">
                  <span class="dp"
                    ><img
                      src="../../assets/template/images/dummyman.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 class="name">Annette Black</h6>
                    <p class="designation">CEO of Netflix</p>
                  </div>
                </div>
                <div class="item-content">
                  <p>
                    Working with my mentor from this platform has been
                    transformative! Their guidance helped me clarify my career
                    goals and develop the skills I needed to succeed. Highly
                    recommend!"
                  </p>
                </div>
                <div class="item-footer">
                  <img
                    src="../../assets/template/images/amazon-logo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- COLUMNS 4 -->
            <div class="item" data-aos="zoom-in-up" data-aos-duration="1000">
              <div class="test-item">
                <div class="item-header d-flex align-items-center">
                  <span class="dp"
                    ><img
                      src="../../assets/template/images/dummyman.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 class="name">Annette Black</h6>
                    <p class="designation">CEO of Netflix</p>
                  </div>
                </div>
                <div class="item-content">
                  <p>
                    Working with my mentor from this platform has been
                    transformative! Their guidance helped me clarify my career
                    goals and develop the skills I needed to succeed. Highly
                    recommend!"
                  </p>
                </div>
                <div class="item-footer">
                  <img
                    src="../../assets/template/images/amazon-logo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- COLUMNS 5 -->
            <div class="item" data-aos="zoom-in-up" data-aos-duration="1000">
              <div class="test-item">
                <div class="item-header d-flex align-items-center">
                  <span class="dp"
                    ><img
                      src="../../assets/template/images/dummyman.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 class="name">Annette Black</h6>
                    <p class="designation">CEO of Youtube</p>
                  </div>
                </div>
                <div class="item-content">
                  <p>
                    Working with my mentor from this platform has been
                    transformative! Their guidance helped me clarify my career
                    goals and develop the skills I needed to succeed. Highly
                    recommend!"
                  </p>
                </div>
                <div class="item-footer">
                  <img
                    src="../../assets/template/images/amazon-logo.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TESTIMONIAL SECTION END -->

    <!-- NEW BLOGS SECTION START -->
    <div class="p-t120 p-b90 site-bg-white blogs-area">
      <div class="container">
        <div class="wt-separator-two-part">
          <div class="row wt-separator-two-part-row m-b30">
            <div class="col-12 wt-separator-two-part-left">
              <!-- TITLE START-->
              <div class="section-head left wt-small-separator-outer">
                <div
                  class="wt-small-separator site-text-primary w-100 d-flex align-items-center justify-content-between flex-wrap"
                >
                  <h4>Latest News</h4>
                  <button class="view-all">View All</button>
                </div>
              </div>
              <!-- TITLE END-->
            </div>
          </div>
        </div>

        <div class="section-content">
          <div
            class="owl-carousel twm-testimonial-1-carousel owl-btn-bottom-center"
          >
            <!-- COLUMNS 1 -->
            <div class="item" data-aos="flip-down" data-aos-duration="1000" data-aos-offset="400">
              <div class="bg-img-container">
                <img src="../../assets/template/images/blog-img.png" alt="" />
              </div>
              <div class="blog-content">
                <div>
                  <span class="tag">Travel</span>
                  <span class="date"
                    ><i class="pi pi-calendar"></i>15 Sep, 2021</span
                  >
                </div>
                <p>
                  From Novice to Expert: The Impact of Mentorship on Skill
                  Development
                </p>
                <a href="">Read More <i class="pi pi-arrow-right"></i></a>
              </div>
            </div>
            <!-- COLUMNS 2 -->
            <div class="item" data-aos="flip-down" data-aos-duration="1000" data-aos-offset="400">
              <div class="bg-img-container">
                <img src="../../assets/template/images/blog-img.png" alt="" />
              </div>
              <div class="blog-content">
                <div>
                  <span class="tag">Travel</span>
                  <span class="date"
                    ><i class="pi pi-calendar"></i>15 Sep, 2021</span
                  >
                </div>
                <p>
                  From Novice to Expert: The Impact of Mentorship on Skill
                  Development
                </p>
                <a href="">Read More <i class="pi pi-arrow-right"></i></a>
              </div>
            </div>
            <!-- COLUMNS 3 -->
            <div class="item" data-aos="flip-down" data-aos-duration="1000" data-aos-offset="400">
              <div class="bg-img-container">
                <img src="../../assets/template/images/blog-img.png" alt="" />
              </div>
              <div class="blog-content">
                <div>
                  <span class="tag">Travel</span>
                  <span class="date"
                    ><i class="pi pi-calendar"></i>15 Sep, 2021</span
                  >
                </div>
                <p>
                  From Novice to Expert: The Impact of Mentorship on Skill
                  Development
                </p>
                <a href="">Read More <i class="pi pi-arrow-right"></i></a>
              </div>
            </div>
            <!-- COLUMNS 4 -->
            <div class="item" data-aos="flip-down" data-aos-duration="1000" data-aos-offset="400">
              <div class="bg-img-container">
                <img src="../../assets/template/images/blog-img.png" alt="" />
              </div>
              <div class="blog-content">
                <div>
                  <span class="tag">Travel</span>
                  <span class="date"
                    ><i class="pi pi-calendar"></i>15 Sep, 2021</span
                  >
                </div>
                <p>
                  From Novice to Expert: The Impact of Mentorship on Skill
                  Development
                </p>
                <a href="">Read More <i class="pi pi-arrow-right"></i></a>
              </div>
            </div>
            <!-- COLUMNS 5 -->
            <div class="item" data-aos="flip-down" data-aos-duration="1000" data-aos-offset="400">
              <div class="bg-img-container">
                <img src="../../assets/template/images/blog-img.png" alt="" />
              </div>
              <div class="blog-content">
                <div>
                  <span class="tag">Travel</span>
                  <span class="date"
                    ><i class="pi pi-calendar"></i>15 Sep, 2021</span
                  >
                </div>
                <p>
                  From Novice to Expert: The Impact of Mentorship on Skill
                  Development
                </p>
                <a href="">Read More <i class="pi pi-arrow-right"></i></a>
              </div>
            </div>
            <!-- COLUMNS 6 -->
            <div class="item" data-aos="flip-down" data-aos-duration="1000" data-aos-offset="400">
              <div class="bg-img-container">
                <img src="../../assets/template/images/blog-img.png" alt="" />
              </div>
              <div class="blog-content">
                <div>
                  <span class="tag">Travel</span>
                  <span class="date"
                    ><i class="pi pi-calendar"></i>15 Sep, 2021</span
                  >
                </div>
                <p>
                  From Novice to Expert: The Impact of Mentorship on Skill
                  Development
                </p>
                <a href="">Read More <i class="pi pi-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- NEW BLOGS SECTION END -->
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { CountUp } from "vue-countup-v3";
import Carousel from "primevue/carousel";

export default {
  components: {
    CountUp,
    Carousel,
  },
  data() {
    return {
      isInView: false, // Track if the element is in view
      jobCategories: [
        "strategic planner",
        "business analytst",
        "management consultatant",
        "Entrepreneurial Coach",
        "Risk Management Specialist",
        "Virtual Mentor",
      ],
    };
  },
  setup() {
    const TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.tick();
      this.isDeleting = false;
    };
    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

      var that = this;
      var delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    const initTxtType = () => {
      var elements = document.getElementsByClassName("typewrite");
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-type");
        var period = elements[i].getAttribute("data-period");
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
      document.body.appendChild(css);
    };
    const isInView = ref(false); // Track if the element is in view
    const countUpSection = ref(null); // Reference to the section

    onMounted(() => {
      initTxtType();
      // Create the Intersection Observer
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            isInView.value = true; // Start CountUp when in view
            observer.unobserve(entry.target); // Stop observing after the first trigger
          }
        });
      });
      // Observe the section
      observer.observe(countUpSection.value);
    });
    // Check if countUpSection is available before observing
    if (countUpSection.value) {
      observer.observe(countUpSection.value);
    } else {
      console.error("countUpSection is not defined");
    }
    return {
      isInView,
      countUpSection,
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.twm-how-it-work-area {
  background: rgba(239, 244, 248, 1);
}

.twm-how-it-work-section h5 {
  font-size: 50px;
  font-weight: 600;
  line-height: 60.51px;
  text-transform: uppercase;
  width: 98%;
}

.twm-how-it-work-section .icon-holder {
  min-width: 60px;
  min-height: 60px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
  background: rgb(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pl {
  padding-left: 21px;
}

.section2subheading {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: rgba(122, 122, 122, 1);
  margin-top: 30px;
}

.stat {
  font-size: 28px;
  font-weight: 700;
  display: flex;
}

.stat2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: rgba(112, 158, 190, 1);
}

.twm-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 33.89px;
  color: var(--primary-blue);
}
.twm-desc {
  color: rgba(122, 122, 122, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.stat-col {
  min-width: 50px;
}

/* section job cat */
.job-category-section {
  padding-top: 33px;
  padding-bottom: 33px;
  background: var(--primary-blue) !important;
  overflow: hidden;
}

.job-category-section ul {
  list-style-type: none;
  padding: 0;
}
.jobcat-li {
  background: rgba(207, 223, 233, 1);
  max-width: 380px;
  padding: 6px 22px;
  margin-bottom: 20px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: var(--primary-blue);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 12px;
}

.job-category-section .section-head.left.wt-small-separator-outer h2 {
  color: white;
  font-size: 50px;
  font-weight: 700;
  line-height: 60.51px;
  text-align: left;
}

.job-category-section .wt-small-separator {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
}
.jcc2 {
  position: relative;
}
.job-cat-t1 {
  position: absolute;
  top: 30px;
  left: 0;
}
.job-cat-t2 {
  position: absolute;
  top: 150px;
  right: -40px;
}
.job-cat-t3 {
  position: absolute;
  top: 250px;
  right: -80px;
}
.job-cat-t4 {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

/* testimonial section */

.twm-testimonial-1-area .item {
  width: 100%;
}

.twm-testimonial-1-area .test-item {
  background: rgba(240, 245, 255, 1);
  border-radius: 24px;
  padding: 40px;
  height: 100%;
  width: 100%;
}
.twm-testimonial-1-area .test-item .dp {
  height: 48px;
  width: 48px;
  display: block;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 12px;
}
.twm-testimonial-1-area .test-item img,
.twm-testimonial-1-area .item-footer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.twm-testimonial-1-area .name {
  margin-bottom: 4px;
  color: rgba(6, 28, 61, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.twm-testimonial-1-area .designation {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.twm-testimonial-1-area .item-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 24px;
}

.twm-testimonial-1-area .item-footer {
  width: 80px;
  height: 40px;
  margin-top: 52px;
  overflow: hidden;
}

.twm-testimonial-1-area ::v-deep .owl-carousel .owl-nav button {
  background: var(--primary-blue);
}
.twm-testimonial-1-area ::v-deep .owl-carousel .owl-nav button i {
  color: white !important;
  font-size: 18px;
}

.twm-testimonial-1-area .wt-small-separator {
  color: rgba(6, 28, 61, 1);
  font-size: 56px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
}

.twm-testimonial-1-area .wt-title {
  color: rgba(122, 122, 122, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

/* blogs section */

.blogs-area {
  background: rgba(239, 244, 248, 1);
}
.blogs-area .owl-carousel .item {
  height: 480px;
  background: white;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
}

.blogs-area .item:hover .bg-img-container img {
  transform: scale(1.05);
}
.blogs-area .item:hover a {
  transform: translateY(0%);
  max-height: 12px;
  transition: 0.5s linear all;
}

.blogs-area .bg-img-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.blogs-area .bg-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.5s linear all;
}
.blogs-area .blog-content {
  position: absolute;
  bottom: 0;
  padding: 32px 15px;
}

.blog-content .tag {
  background: rgba(255, 199, 89, 1);
  height: 24px;
  color: rgba(6, 28, 61, 1);
  border-radius: 100px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-right: 16px;
}

.blog-content .date {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.pi-calendar {
  margin-right: 5px;
}

.blog-content p {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin: 12px 0px;
}

.blog-content a {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  transform: translateY(220%);
  transition: 0.5s linear all;
  max-height: 0;
  overflow: hidden;
}

.blogs-area h4 {
  font-size: 56px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(6, 28, 61, 1);
}

.blogs-area .view-all {
  border: 1.5px solid rgba(112, 158, 190, 1);
  border-radius: 7px;
  padding: 0 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: rgba(51, 87, 113, 1);
  background: none;
}

.blogs-area ::v-deep .owl-nav button i {
  font-size: 18px;
}

.findmentorsection{
  background: rgba(239, 244, 248, 1) !important;
}

/* media queries */
@media (max-width: 992px) {
  .countrow {
    margin-bottom: 50px;
  }
  .jcc2 {
    justify-content: center !important;
  }
  .job-cat-t2 {
    right: 30px;
  }
}
</style>
