<template>
  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <div class="wt-bnr-inr overlay-wraper bg-center">
      <div class="overlay-main site-bg-white opacity-01"></div>
      <div class="container d-flex align-items-center">
        <div
          class="wt-bnr-inr-entry m-t100 d-flex align-items-center justify-content-center"
        >
          <div class="banner-title-outer">
            <div
              class="banner-title-name"
              data-aos="fade-up"
              data-aos-once="false"
            >
              <h2 class="c-maintitle">
                Have Questions? <br />
                We’re Here to Help!
              </h2>
              <p class="c-mainpara">
                Whether you're exploring mentorship options or need more
                information, our dedicated representative will get in touch to
                provide the best solution tailored to your needs ,feel free to
                reach out.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- CONTACT FORM -->
    <div class="section-full contact-form">
      <div class="container">
        <div class="row">
          <div class="form-container">
            <div class="col-lg-5 col-md-12 col-sm-12 left-col">
              <img
                src="../../assets/template/images/formbg.svg"
                class="pattern"
                alt=""
              />
              <h5>Get in touch</h5>
              <ul class="contact-info">
                <li>
                  <div class="icon-holder">
                    <img
                      src="../../assets/template/images/Envelope.svg"
                      alt=""
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="label">Email Us</span>
                    <span class="detail">info@mentorwise.com</span>
                    <span class="detail">support@mentorwise.com</span>
                  </div>
                </li>
                <li>
                  <div class="icon-holder">
                    <img
                      src="../../assets/template/images/PhoneCall.svg"
                      alt=""
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="label">Phone Number</span>
                    <span class="detail">+971 4 336 6430</span>
                  </div>
                </li>
                <li>
                  <div class="icon-holder">
                    <img
                      src="../../assets/template/images/MapPinLine.svg"
                      alt=""
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <!-- <span class="label">Email Us</span> -->
                    <span class="detail">SIT Tower - Office# 1114 Silicon</span>
                    <span class="detail">Oasis, Dubai UAE</span>
                  </div>
                </li>
              </ul>
              <div class="social-container">
                <span class="detail">Connect With Us:</span>
                <ul class="social-ul">
                  <li class="social-icon-container">
                    <i class="pi pi-facebook"></i>
                  </li>
                  <li class="social-icon-container">
                    <i class="pi pi-twitter"></i>
                  </li>
                  <li class="social-icon-container">
                    <i class="pi pi-instagram"></i>
                  </li>
                  <li class="social-icon-container">
                    <i class="pi pi-linkedin"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 right-col">
              <form>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <label for="name" class="form-label">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Full name"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="email" class="form-label">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Email address"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <label for="phone" class="form-label">Phone</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="phone"
                      placeholder="Number"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="subject" class="form-label">Subject</label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      placeholder="Subject here"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label for="message" class="form-label">Message</label>
                  <textarea
                    class="form-control"
                    id="message"
                    rows="4"
                    placeholder="Write your message here"
                    style="height:106px"
                 
                  ></textarea>
                </div>

                <div class="">
                  <button type="submit" class="contact-now-btn">
                    <i class="pi pi-arrow-right r1"></i><span>Send Message</span
                    ><i class="pi pi-arrow-right r2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      formData: {
        username: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      errors: {},
      successMessage: "",
      isSubmitting: false, // Added isSubmitting property
    };
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true; // Set to true when form submission starts
      try {
        const response = await axios.post(
          "http://127.0.0.1:8000/api/contact",
          this.formData
        );
        this.successMessage = response.data.success;
        this.clearForm();
        this.hideSuccessMessage();
      } catch (error) {
        console.error(error);
        this.errors = error.response.data.errors || {};
      } finally {
        this.isSubmitting = false; // Reset to false when form submission ends
      }
    },
    clearForm() {
      this.formData = {
        username: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      };
      this.errors = {};
    },
    hideSuccessMessage() {
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },
  },
};
</script>

<!-- <script>
export default {};
</script> -->

<style scoped>
.wt-bnr-inr {
  background-image: none;
  background: rgba(239, 244, 248, 1);
}

.c-maintitle {
  color: var(--primary-blue);
  font-size: 56px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;
}

.c-mainpara {
  color: rgba(45, 76, 99, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-top: 35px;
}

.contact-form {
  background: rgba(239, 244, 248, 1);
}

.form-container {
  background: white;
  border-radius: 24px;
  padding: 0 !important;
  display: flex;
  overflow: hidden;
  margin-bottom: 40px;
}

.icon-holder {
  height: 64px;
  width: 64px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}
ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 90px;
}

ul li {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.left-col {
  padding: 72px;
  background: var(--primary-blue);
  position: relative;
  overflow: hidden;
}

.left-col h5 {
  color: white;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 40px;
}

.left-col .label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 6px;
}

.left-col .pattern {
  position: absolute;
  right: -122px;
  top: -32px;
}

.left-col .detail {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: white;
}

.social-icon-container {
  width: 44px;
  height: 44px;
  padding: 12px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  transition: 0.3s linear all;
}
.social-icon-container i {
  font-size: 20px;
  color: white;
  transition: 0.3s linear all;
}

.social-ul {
  margin-bottom: 0;
  margin-top: 16px;
  display: flex;
  gap: 18px;
}

.social-ul li {
  cursor: pointer;
}

.social-icon-container:hover {
  background: white;
}
.social-icon-container:hover i {
  color: var(--primary-blue);
}

.right-col {
  padding: 72px;
}

.right-col label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.right-col input::placeholder,
.right-col textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(131, 142, 158, 1);
}

button {
  width: fit-content;
  margin-top: 120px;
}


@media (max-width: 992px) {
  .form-container {
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .left-col,
  .right-col {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 595px) {
  .left-col {
    width: 100%;
  }

  .contact-form {
    padding: 12px;
  }
}
</style>
