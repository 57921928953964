<template>
  <div class="container-fluid">
      <div class="row">
          <!-- <div class="col-md-3 sidebar">
              <nav class="nav flex-column">
                  <a class="nav-link" href="#">Dashboard</a>
                  <a class="nav-link active" href="#">Settings</a>
                  <a class="nav-link" href="#">Manage Account</a>
                  <a class="nav-link" href="#">Notifications</a>
                  <a class="nav-link" href="#">Plans</a>
                  <a class="nav-link" href="#">Billing</a>
              </nav>
          </div> -->
          <div class="col-12 content">
              <h2>Notification Settings</h2>
              <div class="card mb-3">
                  <div class="card-body">
                      <h5 class="card-title mb-3">Email Notifications</h5>
                      <h6 class="card-subtitle mb-2 text-muted">Requests and Messages</h6>
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="emailRequestsMessages" checked>
                          <label class="form-check-label" for="emailRequestsMessages">Email me when I receive a new message</label>
                      </div>
                      <hr>
                      <h6 class="card-subtitle mb-2 text-muted">Community</h6>
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="emailCommunity1" checked>
                          <label class="form-check-label" for="emailCommunity1">Email me when I receive a new message</label>
                      </div>
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="emailCommunity2" checked>
                          <label class="form-check-label" for="emailCommunity2">Email me when I receive a new message</label>
                      </div>
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="emailCommunity3" checked>
                          <label class="form-check-label" for="emailCommunity3">Email me when I receive a new message</label>
                      </div>
                  </div>
              </div>
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Sound Notifications</h5>
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="muteAllSounds">
                          <label class="form-check-label" for="muteAllSounds">Mute all sounds (includes ones for session requests and messages)</label>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
