import {
    createApp
} from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css'; //bootstrap
import 'bootstrap';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primeicons/primeicons.css'
import CountUp from 'vue-countup-v3';
import AOS from 'aos';
import 'aos/dist/aos.css';


const app = createApp(App);
app.use(router);
app.use(PrimeVue);


// Initialize AOS
AOS.init();
// Register CountUp component
app.component('CountUp', CountUp);

// Configure custom elements for count up library
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag === 'count-up'; 
};
app.mount('#app');