<template>
  <Header />

  <!-- forms start -->
  <div
    class="modal fade twm-sign-up"
    id="sign_up_popup"
    aria-hidden="true"
    aria-labelledby="sign_up_popupLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered mt-0">
      <div class="modal-content">
        <form @submit.prevent="registerUser">
          <div class="modal-header">
            <h2 class="modal-title" id="sign_up_popupLabel">Sign Up</h2>
            <p>Sign Up and get access to all the features of Mentorwise</p>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="twm-tabs-style-2">
              <div class="tab-content" id="myTabContent">
                <!--Signup Candidate Content-->
                <div class="tab-pane fade show active" id="sign-candidate">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input
                          id="name"
                          type="text"
                          class="form-control"
                          v-model="formData.name"
                          :class="{ 'is-invalid': errors.name }"
                          placeholder="Username*"
                          autofocus
                        />
                        <span v-if="errors.name" class="text-danger">{{
                          errors.name[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input
                          id="email"
                          type="email"
                          class="form-control"
                          v-model="formData.email"
                          :class="{ 'is-invalid': errors.email }"
                          placeholder="Email*"
                        />
                        <span v-if="errors.email" class="text-danger">{{
                          errors.email[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input
                          id="password"
                          type="password"
                          class="form-control"
                          v-model="formData.password"
                          :class="{ 'is-invalid': errors.password }"
                          placeholder="Password*"
                        />
                        <span v-if="errors.password" class="text-danger">{{
                          errors.password[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input
                          id="cpassword"
                          type="password"
                          class="form-control"
                          v-model="formData.password_confirmation"
                          :class="{
                            'is-invalid': errors.password_confirmation,
                          }"
                          placeholder="Confirm Password*"
                        />
                        <span
                          v-if="errors.password_confirmation"
                          class="text-danger"
                          >{{ errors.password_confirmation[0] }}</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <select
                          class="form-select custom-drop"
                          id="role"
                          v-model="formData.role"
                          :class="{ 'is-invalid': errors.role }"
                        >
                          <option class="custom-option" value="">Select</option>
                          <option class="custom-option" value="user">
                            User
                          </option>
                          <option class="custom-option" value="student">
                            Student
                          </option>
                          <option class="custom-option" value="mentor">
                            Mentor
                          </option>
                          <option class="custom-option" value="mentee">
                            Mentee
                          </option>
                        </select>
                        <span v-if="errors.role" class="text-danger">{{
                          errors.role[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="agree1"
                            v-model="formData.agree"
                          />
                          <label class="form-check-label" for="agree1">
                            I agree to the
                            <a href="javascript:;">Terms and conditions</a>
                          </label>
                          <span v-if="errors.agree" class="text-danger">{{
                            errors.agree[0]
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <button
                        type="submit"
                        class="site-button"
                        :disabled="loading"
                      >
                        Sign Up
                      </button>
                    </div>

                    <div v-if="successMessage" class="alert alert-success mt-3">
                      {{ successMessage }}
                    </div>

                    <div class="col-lg-12 text-center">
                      <div class="form-group mb-3">
                        <p>
                          Already registered?
                          <button
                            class="twm-backto-login"
                            data-bs-target="#sign_up_popup2"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                          >
                            Log in here
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span class="modal-f-title">Login or Sign up with</span>
            <ul class="twm-modal-social">
              <li>
                <a href="javascript" class="facebook-clr">
                  <i class="fab fa-facebook-f p-t8"></i>
                </a>
              </li>
              <li>
                <a href="javascript" class="twitter-clr">
                  <i class="fab fa-twitter p-t8"></i>
                </a>
              </li>
              <li>
                <a href="javascript" class="linkedin-clr">
                  <i class="fab fa-linkedin-in p-t8"></i>
                </a>
              </li>
              <li>
                <a href="javascript" class="google-clr">
                  <i class="fab fa-google p-t8"></i>
                </a>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!--Login Popup Section Start-->
  <div>
    <div
      class="modal fade twm-sign-up"
      id="sign_up_popup2"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered mt-0">
        <div class="modal-content">
          <form @submit.prevent="loginUser" class="m-b0">
            <div class="modal-header">
              <h2 class="modal-title" id="sign_up_popupLabel2">Login</h2>
              <p>Login and get access to all the features of MentorWise</p>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="twm-tabs-style-2">
                <div class="tab-content" id="myTab2Content">
                  <div class="tab-pane fade show active" id="login-candidate">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <input
                            v-model="email"
                            id="email"
                            type="email"
                            class="form-control"
                            required
                            autocomplete="email"
                            autofocus
                            placeholder="Email*"
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <input
                            v-model="password"
                            id="password"
                            type="password"
                            class="form-control"
                            required
                            autocomplete="current-password"
                            placeholder="Password*"
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group mb-3">
                          <div class="form-check">
                            <input
                              v-model="remember"
                              class="form-check-input"
                              type="checkbox"
                              id="remember"
                            />
                            <label
                              class="form-check-label rem-forgot"
                              for="remember"
                            >
                              Remember me
                              <button
                                class="twm-backto-login"
                                data-bs-target="#forget_password_popup"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                              >
                                Forgot Password?
                              </button>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <button type="submit" class="site-button">
                          Log in
                        </button>
                        <div class="mt-3 mb-3 text-center">
                          Don't have an account ?
                          <button
                            class="twm-backto-login"
                            data-bs-target="#sign_up_popup"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <span class="modal-f-title">Login or Sign up with</span>
              <ul class="twm-modal-social">
                <li>
                  <a href="javascript" class="facebook-clr"
                    ><i class="fab fa-facebook-f p-t8"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript" class="twitter-clr"
                    ><i class="fab fa-twitter p-t8"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript" class="linkedin-clr"
                    ><i class="fab fa-linkedin-in p-t8"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript" class="google-clr"
                    ><i class="fab fa-google p-t8"></i
                  ></a>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--Login Popup Section End-->

  <!--Forget-Password Popup Section Start-->
  <div
    class="modal fade twm-sign-up"
    id="forget_password_popup"
    aria-hidden="true"
    aria-labelledby="sign_up_popupLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered mt-0">
      <div class="modal-content">
        <form method="POST" class="m-b0" action="">
          @csrf
          <div class="modal-header">
            <h2 class="modal-title" id="forget_password_popupLabel">
              Forget your password?
            </h2>
            <p>Enter your registered email to send the invitation link</p>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="twm-tabs-style-2">
              <div class="tab-content" id="myTab2Content">
                <!--Login Candidate Content-->
                <div class="tab-pane fade show active" id="forget_password">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <input
                          id="email"
                          type="email"
                          class="form-control"
                          name="email"
                          value=""
                          required
                          autocomplete="email"
                          autofocus
                          placeholder="Email*"
                        />
                      </div>

                      <span class="invalid-feedback" role="alert">
                        <strong></strong>
                      </span>
                    </div>

                    <div class="col-md-12">
                      <button type="submit" class="site-button">
                        Reset Password
                      </button>
                      <div class="mt-3 mb-3">
                        Don't have an account ?
                        <button
                          class="twm-backto-login"
                          data-bs-target="#sign_up_popup"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <span class="modal-f-title">Login or Sign up with</span>
            <ul class="twm-modal-social">
              <li>
                <a href="javascript" class="facebook-clr"
                  ><i class="fab fa-facebook-f p-t8"></i
                ></a>
              </li>
              <li>
                <a href="javascript" class="twitter-clr"
                  ><i class="fab fa-twitter p-t8"></i
                ></a>
              </li>
              <li>
                <a href="javascript" class="linkedin-clr"
                  ><i class="fab fa-linkedin-in p-t8"></i
                ></a>
              </li>
              <li>
                <a href="javascript" class="google-clr"
                  ><i class="fab fa-google p-t8"></i
                ></a>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Forget-Password Popup Section End-->

  <!-- forms  end-->

  <div class="middle-content">
    <router-view />
  </div>
  <!-- BUTTON TOP START -->
  <button class="scroltop">
    <span class="fa fa-angle-up relative" id="btn-vibrate"></span>
  </button>

  <Footer />
</template>

<script>
import Header from "../components/website/Header.vue";
import Footer from "../components/website/Footer.vue";
import "@/assets/template/css/style.css";
import "@/assets/template/css/mystyle.css";

import "@/assets/template/js/jquery-3.6.0.min.js"; // Import jQuery
import "@/assets/template/js/popper.min.js"; // Import Popper.js
import "@/assets/template/css/bootstrap.min.css";
import "@/assets/template/css/font-awesome.min.css";
import "@/assets/template/css/feather.css";
import "@/assets/template/css/owl.carousel.min.css";
import "@/assets/template/js/owl.carousel.min.js"; // Import Owl Carousel
import "@/assets/template/css/magnific-popup.min.css";
// import "@/assets/template/css/lc_lightbox.css";
import "@/assets/template/css/bootstrap-select.min.css";
import "@/assets/template/css/dataTables.bootstrap5.min.css";
import "@/assets/template/css/select.bootstrap5.min.css";
import "@/assets/template/css/dropzone.css";
import "@/assets/template/css/scrollbar.css";
import "@/assets/template/css/datepicker.css";
import "@/assets/template/js/bootstrap.min.js"; // Import Bootstrap
import "@/assets/template/js/magnific-popup.min.js"; // Import Magnific Popup
import "@/assets/template/js/waypoints.min.js"; // Import Waypoints
import "@/assets/template/js/waypoints-sticky.min.js"; // Import Waypoints Sticky
import "@/assets/template/js/counterup.min.js"; // Import CounterUp
import "@/assets/template/js/isotope.pkgd.min.js"; // Import Isotope
import "@/assets/template/js/imagesloaded.pkgd.min.js"; // Import ImagesLoaded
import "@/assets/template/js/theia-sticky-sidebar.js"; // Import Theia Sticky Sidebar
import "@/assets/template/js/lc_lightbox.lite.js"; // Import Lc Lightbox
// import '@/assets/template/js/bootstrap-select.min.js'; // Import Bootstrap Select
import "@/assets/template/js/dropzone.js"; // Import Dropzone
import "@/assets/template/js/jquery.scrollbar.js"; // Import Scrollbar
// import '@/assets/template/js/bootstrap-datepicker.js'; // Import Datepicker
// import '@/assets/template/js/jquery.dataTables.min.js'; // Import DataTables
// import '@/assets/template/js/dataTables.bootstrap5.min.js'; // Import DataTables Bootstrap 5
import "@/assets/template/js/chart.js"; // Import Chart.js
import "@/assets/template/js/custom.js"; // Import your custom JavaScript file

import axios from "axios";

export default {
  components: { Header, Footer },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "",
        agree: false,
      },
      errors: {},
      successMessage: "",
      loading: false,
    };
  },
  methods: {
    async registerUser() {
      this.loading = true;
      this.errors = {};

      try {
        const response = await axios.post(
          "http://127.0.0.1:8000/api/register",
          this.formData
        );
        this.successMessage = response.data.success;
        this.clearForm();
        this.hideSuccessMessage();
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.formData = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "",
        agree: false,
      };
      this.errors = {};
    },
    hideSuccessMessage() {
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },
  },
};
</script>

<style scoped>
/* @import "@/assets/template/css/style.css";
@import "@/assets/template/css/mystyle.css"; */
</style>
