<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9">
        <section class="first-section">
          <h3 class="section-heading">Get Started</h3>
          <div class="row">
            <div
              class="col-lg-4 col-md-12"
              v-for="item in cardData"
              :key="item.title"
            >
              <div class="card section-style">
                <img
                  v-if="item.icon"
                  :src="getIconPath(item.icon)"
                  alt="Icon"
                />
                <div>
                  <h4>{{ item.title }}</h4>
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="top-mentors-section my-5">
          <h3 class="section-heading">Top Mentors</h3>
          <TabView class="section-style">
            <TabPanel header="First Session Promo">
              <div
                class="d-flex align-items-center justify-content-between mentor-container"
                v-for="index in 3"
                :key="index"
              >
                <div class="d-flex">
                  <div class="avatar-container">
                    <Avatar
                      class="p-overlay-badge h-100 w-100"
                      image="https://primefaces.org/cdn/primevue/images/organization/walter.jpg"
                      size="xlarge"
                      shape="circle"
                    />
                    <Badge severity="success" class="avatar-badge" />
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="mentor d-flex flex-column">
                      <span class="mentor-name mb-2">Patrick Doe</span>
                      <span class="mentor-designation"
                        >Senior Ios Developer</span
                      >
                    </div>
                    <div class="ratings d-flex flex-column">
                      <span class="mb-2">5.0</span>
                      <Rating
                        v-model="ratingValue"
                        readonly
                        :cancel="false"
                        class="rating-stars"
                      />
                    </div>
                    <div class="reviews d-flex flex-column">
                      <span class="mb-2">Reviews</span>
                      <span>5550</span>
                    </div>
                  </div>
                </div>
                <div class="message-icon">
                  <img src="../assets/images/icons/message-icon.svg" alt="" />
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Favorite">
              <p class="m-0">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
                modi.
              </p>
            </TabPanel>
          </TabView>
        </section>
        <section class="events-section">
          <h3 class="section-heading">Free Developer Events For You</h3>
          <p class="section-para">
            Learn technical skills and grow your career through free talks and
            events.
          </p>

          <div class="section-style">
            <div class="col-lg-4 col-md-12" v-for="index in 3" :key="index">
              <Card>
                <template #title>
                  <p class="event-date">Oct 28</p>
                  Build a Real-time Database with AWS: A Firebase
                  Alternative</template
                >
                <!-- <template #content>
                <p class="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Inventore sed consequuntur error repudiandae numquam deserunt
                  quisquam repellat libero asperiores earum nam nobis, culpa
                  ratione quam perferendis esse, cupiditate neque quas!
                </p>
              </template> -->
                <template #footer>
                  <Avatar
                    class=""
                    image="https://primefaces.org/cdn/primevue/images/organization/walter.jpg"
                    size="large"
                    shape="circle"
                  />
                  <div>
                    <h5>Patrick Doe</h5>
                    <p>Senior iOS Developer</p>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </section>
      </div>
      <div class="col-md-3 rightbar">
        <div class="card section-style payment-method">
          <h5 class="mb-5">Payment Method</h5>
          <div class="d-flex flex-column align-items-center text-center">
            <img
              src="../assets/images/icons/wallet-icon.svg"
              alt=""
              class="mb-3"
              style="height: 40px; width: 50px"
            />
            <h4>Add Card & Buy Credits</h4>
            <p class="mb-0">Lorem ipsum dolor sit amet ipsum olor sit amet</p>
          </div>
        </div>
        <div class="help-support">
          <h5>Help & Support</h5>
          <ul>
            <li v-for="item in QA" :key="item">
              <a href="">{{ item }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Rating from "primevue/rating";
import Card from "primevue/card";
export default {
  components: { TabView, TabPanel, Avatar, Badge, Rating, Card },
  data() {
    return {
      cardData: [
        {
          title: "Avail 1 Free Session",
          description: "1:1 mentorship session",
          icon: "free-session-icon.png",
        },
        {
          title: "Get Mentor Help",
          description: "Lorem ipsum dolor sit amet",
          icon: "support-icon.png",
        },
        {
          title: "get your linkedIn certificate",
          description: "Lorem ipsum dolor sit amet",
          icon: "certificate-icon.png",
        },
      ],
      ratingValue: 5,
      QA: [
        "How do live 1:1 sessions work?",
        "Contact our support team",
        "Share your feedback",
      ],
    };
  },
  methods: {
    getIconPath(iconName) {
      return require(`@/assets/images/icons/${iconName}`);
    },
  },
};
</script>

<style scoped>
.section-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.section-style {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.first-section .card {
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  text-align: center;
}

.first-section .card h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-transform: capitalize;
  text-align: center;
}

.first-section .card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.first-section .card img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.top-mentors-section ::v-deep .p-tabview-nav {
  padding: 0;
  margin: 0;
}

.top-mentors-section ::v-deep .p-tabview-title {
  color: var(--primary-blue);
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}

.top-mentors-section ::v-deep .p-tabview-nav-link {
  text-decoration: none;
  background: transparent;
}

.top-mentors-section ::v-deep .p-tabview-ink-bar {
  width: 0 !important;
}

.top-mentors-section ::v-deep .p-highlight {
  background: var(--primary-blue) !important;
}
.top-mentors-section ::v-deep .p-highlight .p-tabview-title {
  color: white !important;
}

.avatar-container {
  width: 50px;
  height: 50px;
  position: relative;
  margin-right: 20px;
}

.avatar-container .avatar-badge {
  position: absolute;
  bottom: 0;
  border: 3px solid white;
  height: 16px;
  width: 16px;
  left: 38px;
  /* top: 47px; */
  background: #7fd3b7;
}

.ratings {
  border-right: 1px solid #a4a4a4;
  border-left: 1px solid #a4a4a4;
  height: 100%;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  /* text-align: center; */
  /* padding: 0px 50px; */
  margin: 0px 20px;
  padding: 0px 15px;
  width: 127px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  text-align: left;
}

.reviews span:first-child {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.85px;
  text-align: left;
}

.reviews span:nth-child(2) {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.85px;
  text-align: left;
}

.ratings ::v-deep .p-icon {
  color: #ffc100;
}

.message-icon {
  cursor: pointer;
}

.mentor .mentor-name {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  text-align: left;
}

.mentor .mentor-designation {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.85px;
  text-align: left;
}

.section-style .p-tabview-nav .p-tabview-nav-link.p-highlight {
  background-color: blue;
  color: white;
}

.mentor-container {
  border-bottom: 1px solid #a4a4a4;
  padding: 15px 0px;
}
.mentor-container:last-child {
  border: none;
}

.events-section ::v-deep .p-card-footer {
  background: #f9f9f9;
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0px;
}

.events-section ::v-deep .p-card-footer h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  text-align: left;
}

.events-section ::v-deep .p-card-footer p {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.85px;
  text-align: left;
  margin: 0;
}
.events-section ::v-deep .p-card-body {
  padding: 0;
  gap: 0px;
}

.events-section ::v-deep .p-card-title {
  padding: 20px 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.events-section .event-date {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  text-align: left;
  color: var(--primary-blue);
  margin-bottom: 10px;
}

.events-section ::v-deep .p-card {
  border-radius: 5px;
  border: 1px solid #cfcfcf;
}

.events-section .section-style {
  padding: 40px 25px;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.payment-method h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  text-align: left;
}

.payment-method h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}

.payment-method p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.payment-method {
  padding: 22px 16px;
}

.rightbar {
  padding-right: 0;
  padding-top: 29px;
}

.help-support {
  margin-top: 40px;
}
.help-support ul {
  padding: 0;
  list-style-type: none;
}
.help-support li {
  margin-bottom: 5px;
}
.help-support a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: black;
}

.help-support h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
}

@media (max-width: 992px) {
  .first-section .col-md-12 {
    margin-bottom: 10px;
  }
  .events-section .section-style {
    flex-wrap: wrap;
  }
}
</style>
