<script>
import FullLayout from "./views/FullLayout.vue";
import ContentLayout from "./views/ContentLayout.vue";
import WebLayout from "./views/WebLayout.vue";

export default {
  components: { FullLayout, ContentLayout, WebLayout },
};
</script>
<template>
  <!-- <FullLayout /> -->
  <!-- <WebLayout /> -->
  <router-view />
</template>
