<template>
  <div class="row">
    <div class="col-12 content">
     <div class="d-flex justify-content-between align-items-center mb-4">
      <h2>
       Session History
      </h2>
      <div>
       <button class="btn btn-outline-secondary me-2">
        Today
       </button>
       <button class="btn btn-outline-secondary me-2">
        <i class="fas fa-chevron-left">
        </i>
       </button>
       <button class="btn btn-outline-secondary me-2">
        November 2023
       </button>
       <button class="btn btn-outline-secondary">
        <i class="fas fa-chevron-right">
        </i>
       </button>
       <button class="btn btn-outline-secondary ms-2">
        Week
       </button>
      </div>
     </div>
     <div class="session-history">
      <div class="session-item">
       <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/pOf7b7RNuA1zXyXVSExRR6pgBRlbe00XNgRBPeyPHwGcqvInA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         Patrick Doe
        </div>
        <div class="role">
         Senior iOS Developer
        </div>
       </div>
       <img alt="Profile picture of John Doe" height="50" src="https://storage.googleapis.com/a1aa/image/eKPSUZax2UXrb6lTfdwIBtli91KWipJidhNwn5gzASgN1XkTA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         John Doe
        </div>
        <div class="role">
         Student
        </div>
       </div>
       <div class="session-date">
        <div class="name">
          Date
        </div>
        <div>
          20 Jun 2023
        </div>
       </div>
       <div class="session-time">
        <div class="name">
          Time
        </div>
        <div>
          30 minutes Session
        </div>
       </div>
      </div>
      <div class="session-item">
       <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/pOf7b7RNuA1zXyXVSExRR6pgBRlbe00XNgRBPeyPHwGcqvInA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         Patrick Doe
        </div>
        <div class="role">
         Senior iOS Developer
        </div>
       </div>
       <img alt="Profile picture of John Doe" height="50" src="https://storage.googleapis.com/a1aa/image/eKPSUZax2UXrb6lTfdwIBtli91KWipJidhNwn5gzASgN1XkTA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         John Doe
        </div>
        <div class="role">
         Student
        </div>
       </div>
       <div class="session-date">
        <div class="name">
          Date
        </div>
        <div>
          20 Jun 2023
        </div>
       </div>
       <div class="session-time">
        <div class="name">
          Time
        </div>
        <div>
          30 minutes Session
        </div>
       </div>
      </div>
      <div class="session-item">
       <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/pOf7b7RNuA1zXyXVSExRR6pgBRlbe00XNgRBPeyPHwGcqvInA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         Patrick Doe
        </div>
        <div class="role">
         Senior iOS Developer
        </div>
       </div>
       <img alt="Profile picture of John Doe" height="50" src="https://storage.googleapis.com/a1aa/image/eKPSUZax2UXrb6lTfdwIBtli91KWipJidhNwn5gzASgN1XkTA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         John Doe
        </div>
        <div class="role">
         Student
        </div>
       </div>
       <div class="session-date">
        <div class="name">
          Date
        </div>
        <div>
          20 Jun 2023
        </div>
       </div>
       <div class="session-time">
        <div class="name">
          Time
        </div>
        <div>
          30 minutes Session
        </div>
       </div>
      </div>
      <div class="session-item">
       <img alt="Profile picture of Patrick Doe" height="50" src="https://storage.googleapis.com/a1aa/image/pOf7b7RNuA1zXyXVSExRR6pgBRlbe00XNgRBPeyPHwGcqvInA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         Patrick Doe
        </div>
        <div class="role">
         Senior iOS Developer
        </div>
       </div>
       <img alt="Profile picture of John Doe" height="50" src="https://storage.googleapis.com/a1aa/image/eKPSUZax2UXrb6lTfdwIBtli91KWipJidhNwn5gzASgN1XkTA.jpg" width="50"/>
       <div class="session-info">
        <div class="name">
         John Doe
        </div>
        <div class="role">
         Student
        </div>
       </div>
       <div class="session-date">
        <div class="name">
          Date
        </div>
        <div>
          20 Jun 2023
        </div>
       </div>
       <div class="session-time">
        <div class="name">
          Time
        </div>
        <div>
          30 minutes Session
        </div>
       </div>
      </div>
     </div>
    </div>
  </div>
</template>
<style scoped>
.session-history {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}
.session-history .session-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e9ecef;
  /* border-left: 1px solid #e9ecef; */

}
.session-history .session-item:last-child {
  border-bottom: none;
}
.session-history .session-item img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.session-history .session-item .session-info,
.session-history .session-item .session-date,
.session-history .session-item .session-time {
  flex-grow: 1;
}
.session-history .session-item .session-info .name,
.session-history .session-item .session-date .name,
.session-history .session-item .session-time .name {
  font-weight: bold;
}
.session-history .session-item .session-info .role {
  color: #6c757d;
}

</style>
